










import {Vue, Component, Prop} from "vue-property-decorator";
import Icon from "@/components/helpers/Icon.vue";
import {canScanBarcode} from "@/api/BarcodeDetection";

@Component({components: {Icon}})
export default class BarcodeScanner extends Vue {
    @Prop({type: Array, default: () => ["ean_13", "ean_8"]}) formats!: string[];

    showVideo = true;

    interval: NodeJS.Timeout | null = null;
    timeout: NodeJS.Timeout | null = null;
    canvas: OffscreenCanvas | null = null;

    async setup() {
        if (!await canScanBarcode())
            this.$emit("error");

        const barcodeScanner = this.$refs["barcodeScanner"] as HTMLElement;

        await barcodeScanner.requestFullscreen();
        const player = this.$refs["video"] as HTMLVideoElement;
        const videoOverlay = this.$refs["video-overlay"] as HTMLElement;

        await navigator.mediaDevices.getUserMedia({video: {facingMode: "environment"}})
            .then(async (stream) => {
                player.srcObject = stream;
                await player.play();

                this.timeout = setTimeout(() => {
                    this.close();
                }, 10000);

                this.canvas = new OffscreenCanvas(videoOverlay.offsetWidth, videoOverlay.offsetHeight);

                this.interval = await setInterval(async () => {
                    if (this.canvas && window.BarcodeDetector) {
                        // Draw the video frame to the canvas.
                        this.canvas?.getContext("2d")?.drawImage(player, videoOverlay.offsetLeft, videoOverlay.offsetTop,
                            videoOverlay.offsetWidth, videoOverlay.offsetHeight, 0, 0, this.canvas.width, this.canvas.height);

                        const barcodeDetector = new window.BarcodeDetector({
                            formats: this.formats
                        });
                        const data = await barcodeDetector.detect(this.canvas);
                        if (data.length > 0) {
                            if ("vibrate" in navigator)
                                navigator.vibrate(250);
                            this.cleanup();
                            this.$emit("scanned", data[0].rawValue);
                        }
                    }
                }, 500);
            })
            .catch(err => {
                this.cleanup();
                this.$emit("error");

                throw err;
            });
    }

    mounted() {
        this.setup();
    }

    beforeUnmount() {
        this.cleanup();
    }

    close() {
        this.cleanup();

        this.$emit("scanned", null);
    }

    cleanup() {
        if (this.interval)
            clearInterval(this.interval);
        if (this.timeout)
            clearInterval(this.timeout);
        // @ts-ignore
        (this.$refs["video"] as HTMLVideoElement).srcObject?.getVideoTracks().forEach(track => track.stop());
        this.showVideo = false;
        document.exitFullscreen();
    }
}
